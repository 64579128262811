import { TrustedForm } from './thirdparty/TrustedForm'
import { Jornaya } from './thirdparty/Jornaya'
import { Ringba } from './thirdparty/Ringba'
import { Hook } from './enums/HookEnum'

/* eslint-disable camelcase */
const trustedForm = new TrustedForm()
const ringba = new Ringba()
const jornaya = new Jornaya()

export const HookToFunctionMap = {
    [ Hook.BeforeStepSubmission ]: beforeStepSubmission,
    [ Hook.AfterEnterThankYouPage ]: afterEnterThankYouPage,
    [ Hook.OnWebFormStart ]: onWebFormStart,
}

export function beforeStepSubmission(formData, metadata) {

    formData.language = getLanguage()

    const trustedFormUrl = trustedForm.getTrustedFormUrl()
    const pubId = getPubIdFromUrl()

    if (trustedFormUrl) {
        formData.trusted_form_url = trustedFormUrl
    }

    const clonedFormData = Object.assign({}, formData)

    // Remove step so we don't send it to Third Parties
    delete clonedFormData.step

    if (pubId) {

        formData.pub_id = pubId
        ringba.push({ pub_id: pubId })

    }

    ringba.push({ language: getLanguage() })
    ringba.pushFormData(clonedFormData)

    if (typeof dtpCallback === 'function' && pubId === null) {

        dtpCallback(function(clickId) {

            formData.pub_id = clickId
            ringba.push({ pub_id: clickId })

        })

    }

    const leadiD = jornaya.getLeadiDToken()

    if (leadiD) {
        formData.jornaya_id = leadiD
    }

    // Include Impression ID if it exists
    try {

        const storageKey = localStorage.getItem(`ringbaNumber_${ window.RINGBA_TAG }`)

        if (storageKey) {
            formData.ringba_impression_id = JSON.parse(storageKey).impressionQueryPath
        }

    } catch (error) {
        // Prevent errors from showing in console.
    }

    // Associate vertical to leads
    if (metadata.vertical) {
        formData.vertical = metadata.vertical
    }

}

export function afterEnterThankYouPage(metadata) {

    ringba.push({
        thankYouPage: true,
        ThankYouPageId: metadata.key,
    })

    if (metadata.tags) {

        for (const key in metadata.tags) {
            ringba.push({ [ `thank_you_page_${ key }` ]: metadata.tags[ key ] })
        }

    }

}

export function onWebFormStart() {

    if ((window.__SCRIPTS.trustedForm ?? true)) {
        trustedForm.createScript()
    }

    if ((window.__SCRIPTS.jornaya ?? true)) {
        jornaya.createScript()
    }

}

export function beforeChatConversationUpdated(formData, metadata) {

    const formDataMetadata = {}

    /*
     * This will be called before we send the raw chat conversation to the server so that we include any
     * additional data we want to attach to the visitor answer.
     */
    formDataMetadata.language = getLanguage()

    const trustedFormUrl = trustedForm.getTrustedFormUrl()
    const pubId = getPubIdFromUrl()

    if (trustedFormUrl) {
        formDataMetadata.trusted_form_url = trustedFormUrl
    }

    if (pubId) {
        formDataMetadata.pub_id = pubId
    } else if (typeof dtpCallback === 'function') {

        dtpCallback(function(clickId) {
            formDataMetadata.pub_id = clickId
        })

    }

    // Include Impression ID if it exists
    try {

        const storageKey = localStorage.getItem(`ringbaNumber_${ window.RINGBA_TAG }`)

        if (storageKey) {
            formDataMetadata.ringba_impression_id = JSON.parse(storageKey).impressionQueryPath
        }

    } catch (error) {
        // Prevent errors from showing in console.
    }

    // Associate vertical to leads
    if (metadata.vertical) {
        formDataMetadata.vertical = metadata.vertical
    }

    formData.metadata = formDataMetadata

}

export function afterChatConversationUpdated(formData, latestAnswer) {

    /*
     * This will be called after we receive the updated conversation from the server so that we can send the correct
     * data to the Third parties. We merge the formData with the processed answer from the server.
     */
    const clonedFormData = cloneFormData({ ...formData.metadata, ...latestAnswer })
    const pubId = getPubIdFromUrl()

    if (pubId) {
        ringba.push({ pub_id: pubId })
    } else if (typeof dtpCallback === 'function') {

        dtpCallback(function(clickId) {
            ringba.push({ pub_id: clickId })
        })

    }

    ringba.push({ language: getLanguage() })
    ringba.pushFormData(clonedFormData)

}

function getPubIdFromUrl() {

    const pubId = location.search.match(/pub_id=(?<id>[\w|-]+)/)

    if (pubId && pubId.groups && pubId.groups.id) {
        return pubId.groups.id
    }

    return null

}

function getLanguage() {

    const languageMap = {
        en: 'english',
        es: 'spanish',
    }

    const languageCode = navigator.language.slice(0, 2)

    return languageMap[ languageCode ] || null

}

function cloneFormData(formData) {

    const clonedFormData = Object.assign({}, formData)

    // Remove step so we don't send it to Third Parties
    delete clonedFormData.step

    return clonedFormData

}
