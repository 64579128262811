import State from './State'
import Controller from './Controller'
import Component from './Component'

const Loading = new class {

    get component() {
        return Component
    }

    get state() {
        return State
    }

    get controller() {
        return Controller
    }

}()

export default Loading
