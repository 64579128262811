import Alpine from 'alpinejs'
import { ActivePage } from '../../enums/ActivePage'
import State from './State'

const LoadingController = new class {

    async start() {

        State.content = await fetch(`/fast/loading-page/${ Alpine.store('state').visitorId }`)
            .then(response => response.json())
            .then(data => data.data.view)

        Alpine.store('qv').navigation.activePage = ActivePage.Loading
        Alpine.store('qv').navigation.goToLoadingSteps()

    }

}()

export default LoadingController
