import { err, ok } from 'neverthrow'

export class PlanRecommendation {

    token = null
    visitor = null
    action

    constructor() {

        this.token = this.getCSRFToken()
        this.action = 'start_web_form'

    }

    async post(url, data) {

        return this.handleResponse(fetch(this.url(url), {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                accept: 'application/json',
                'content-type': 'application/json',
                'x-csrf-token': this.token,
            },
        }))

    }

    async put(url, data) {

        return this.handleResponse(fetch(this.url(url), {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                accept: 'application/json',
                'content-type': 'application/json',
                'x-csrf-token': this.token,
            },
        }))

    }

    async handleResponse(fetch) {

        try {

            const response = await fetch

            if (response.status === 204) {
                return ok(null)
            }

            const value = await response.json()

            if (response.ok && value.success) {
                return ok(value)
            }

            return err(value)

        } catch (error) {
            return err(error)
        }

    }

    /**
     *  Store the visitor UUID
     * @param {string} visitor
     */
    setVisitor(visitor) {
        this.visitor = visitor
    }

    getCSRFToken() {

        const meta = document.querySelector('meta[name="csrf-token"]')

        return meta.content

    }

    url(url) {

        return window.__STATE.domain.content.path
            ? `/${ window.__STATE.domain.content.path }/${ url }`
            : `/${ url }`

    }

    /**
     * @param {int} planId
     * @return {Promise<Ok<null, never>|Ok<{success}|*, never>|Err<never, *>|Err<never, *>|undefined>}
     */
    selectPlan(planId) {
        return this.post(`fast/visitors/${ this.visitor }/plan-selection/${ planId }`, {})
    }

    /**
     * @param {string} zipcode
     * @return {Promise<Ok<null, never>|Ok<{success}|*, never>|Err<never, *>|Err<never, *>|undefined>}
     */
    updateZipCode(zipcode) {
        return this.put(`fast/visitors/${ this.visitor }/zipcode/${ zipcode }`)
    }

    /**
     * Patch the contents of the plan summary if the plan was selected after initial summary TYP load
     *
     * @param {string} blokId
     * @param {int} thankYouPageId
     * @param {boolean} editing
     * @return {Promise<Ok<null, never>|Ok<{success}|*, never>|Err<never, *>|Err<never, *>|undefined>}
     */
    patchPlanSummary(blokId, thankYouPageId, editing) {

        return this.post(`fast/visitors/${ this.visitor }/plan-summary`, {
            blok: blokId,
            typ: thankYouPageId,
            editing,
        })

    }

    /**
     *  Patch the contents of the plan recommendation after a change in zipcode
     *
     * @param {string} zipcode
     * @param {bool} useFallback
     * @param {string} blokId
     * @param {null|int} typId
     * @return {Promise<Ok<null, never>|Ok<{success}|*, never>|Err<never, *>|Err<never, *>|undefined>}
     */
    patchPlanRecommendation(zipcode, useFallback, blokId, typId) {

        return this.post(`fast/visitors/${ this.visitor }/plan-recommendation`, {
            zipcode,
            // eslint-disable-next-line camelcase
            use_fallback: useFallback,
            blok: blokId,
            typ: typId,
        })

    }

    /**
     * Store the action to be used when plan selected
     * @param {string} action
     */
    setSubmitAction(action) {
        this.action = action
    }

    /**
     * Get the action to be used when plan selected
     * @return {string}
     */
    submitAction() {
        return this.action
    }

}
