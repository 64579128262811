import { Ringba } from './thirdparty/Ringba.js'
import { HookRunner } from './HookRunner.js'
import { Hook } from './enums/HookEnum.js'
import { Action } from './enums/ActionEnum.js'
import { AudioManager } from './AudioManager.js'
import { StepHistoryStore } from './StepHistoryStore.js'
import { MediaAlpha } from './thirdparty/MediaAlpha.js'
import { Navigation } from './routing/Navigation.js'
import { Helpers } from './Helpers.js'
import { Modal } from './Modal.js'
import SmartySDK from 'smartystreets-javascript-sdk'
import Alpine from 'alpinejs'
import SpouseChildren from './components/SpouseChildren.js'
import AlpineSmartyAddress from './components/AlpineSmartyAddress.js'
import QvMask from './alpine/directives/mask'
import QvRouter from './routing/router/index.js'
import { QuinnStreet } from './thirdparty/QuinnStreet.js'
import WebForm from './pages/WebForm/index.js'
import Loading from './pages/Loading/index.js'
import Chat from './pages/Chat/index.js'
import ThankYou from './pages/ThankYou/index.js'
import { ActivePage } from './enums/ActivePage.js'
import { PlanRecommendation } from './PlanRecommendation.js'

const SmartyCore = SmartySDK.core
const Lookup = SmartySDK.usAutocompletePro.Lookup
const key = import.meta.env.VITE_SMARTY_LICENSE
const credentials = new SmartyCore.SharedCredentials(key)
const clientBuilder = new SmartyCore.ClientBuilder(credentials).withLicenses([ 'us-autocomplete-pro-cloud' ])
const client = clientBuilder.buildUsAutocompleteProClient()

window.Alpine = Alpine
window.debounce = Alpine.debounce
Alpine.plugin(QvRouter)
Alpine.plugin(QvMask)
Alpine.data('smartyAddress', AlpineSmartyAddress)
Alpine.data('spouseChildren', SpouseChildren)

Alpine.store('qv', {
    helpers: new Helpers(),
    navigation: new Navigation(),
    ringba: new Ringba(),
    hookRunner: new HookRunner(),
    mediaAlpha: new MediaAlpha(),
    metadata: window.__METADATA ?? {},
    quinnStreet: new QuinnStreet(),
    smartyClient: client,
    smartyLookup: Lookup,
    audioManager: new AudioManager(),
    stepHistory: new StepHistoryStore(),
    chat: Chat,
    webForm: WebForm,
    loading: Loading,
    thankYou: ThankYou,
    activePages: ActivePage,
    modal: new Modal(),
    plans: new PlanRecommendation(),

    getCsrfToken() {

        const meta = document.querySelector('meta[name="csrf-token"]')

        return meta.content

    },

    /**
     * @param {string} url
     * @param {string} offset
     * @returns {void}
     */
    playWebFormAudio(url, offset) {

        if (this.stepHistory.hasAnsweredStep(offset)) {
            return
        }

        this.audioManager.autoplayStepAudio(url)

    },

    runBeforeStepSubmissionHook(formData) {
        this.hookRunner.run(Hook.BeforeStepSubmission, formData, this.metadata)
    },

    runAfterStepSubmissionHook(formData) {
        this.hookRunner.run(Hook.AfterStepSubmission, formData, this.metadata)
    },

    runAfterFormSubmissionHook(formData) {
        this.hookRunner.run(Hook.AfterFormSubmission, formData, this.metadata)
    },

    runBeforeFormSubmissionHook(formData) {
        this.hookRunner.run(Hook.BeforeFormSubmission, formData, this.metadata)
    },

    runAfterEnterThankYouPageHook(metadata) {
        this.hookRunner.run(Hook.AfterEnterThankYouPage, metadata)
    },

    runOnWebFormStartHook() {
        this.hookRunner.run(Hook.OnWebFormStart, this.metadata)
    },

    handleAction(action, ringbaPhoneNumber, callback = null) {

        switch (action?.type) {

        case Action.InternalLink:
            window.location.href = action.url
            callback?.call()

            return

        case Action.ThunderboltLink:
            window.location.href = action.url
            callback?.call()

            return

        case Action.ExternalLink:
            window.open(action.url, '_blank')
            callback?.call()

            return

        case Action.TriggerDialer:
            window.open(`tel:${ action.phoneNumber || ringbaPhoneNumber.number }`, '_self')
            callback?.call()

            return

        case Action.StartWebForm: {

            if (this.navigation.start()) {
                callback?.call()
            }

        }

        }

        return null

    },

})

Alpine.start()

if (import.meta.hot) {

    import.meta.hot.accept(() => {

        // Force Alpine to reload and re-evaluate the page
        window.location.reload()

    })

}
