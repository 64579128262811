import Component from './Component'
import State from './State'
import Controller from './Controller'

const Chat = new class {

    get component() {
        return Component(this)
    }

    get state() {
        return State
    }

    get controller() {
        return Controller
    }

}()

export default Chat
