import Alpine from 'alpinejs'

const state = Alpine.reactive({
    formData: {
        content: '',
    },
    loading: true,
    htmlContent: '',
    submitting: false,
    messages: [],
    chatCompleted: false,
    pollIntervalId: null,
    error: null,
    pollCounter: 0,
    slowResponseTimeThresholdSeconds: 20,

    get lastMessageId() {

        if (this.messages.length === 0) {
            return null
        }

        return this.messages[ this.messages.length - 1 ].id

    },

    setFormData(value) {
        this.formData.content = value
    },

})

export default state
