import State from './State'
import Controller from './Controller'
import Component from './Component'

const ThankYou = new class {

    get component() {
        return Component(this)
    }

    get state() {
        return State
    }

    get controller() {
        return Controller
    }

}()

export default ThankYou
