import Alpine from 'alpinejs'

export default function replaceNavigation(step) {

    if (Alpine.store('qv').navigation.hasInlineWebForm && Alpine.store('qv').navigation.isInlineWithRedirect) {
        return step === 2
    }

    if (Alpine.store('qv').navigation.hasInlineWebForm) {
        return false
    }

    return step === 1

}
