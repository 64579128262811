export default function(WebForm) {

    return (currentOffset, audio, seenTCPAId, recordTCPA) => ({
        formData: {},
        currentOffset,
        audio,
        seenTCPAId,
        recordTCPA,
        submitForm() {

            const data = { ...this.formData, offset: this.currentOffset }

            if (recordTCPA && this.seenTCPAId !== null) {
                data.seenTCPAId = this.seenTCPAId
            }

            WebForm.controller.submitAnswer(
                this.currentOffset,
                data,
            )

        },

        setFormData(key, value) {
            this.formData[ key ] = value
        },

        init() {

            this.$store.qv.playWebFormAudio(this.audio, this.currentOffset)

            this.$nextTick(() => {
                this.$store.qv.modal.bindModalHandlers()
            })

        },
    })

}
