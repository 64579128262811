export default steps => ({
    index: 0,
    steps,
    interval: null,

    get stepDelay() {
        return 6 * 1000 / (this.steps.length || 1)
    },

    progress() {

        const current = this.steps[ this.index ]
        const previous = this.index === 0 ? null : this.steps[ this.index - 1 ]

        if (this.index === this.steps.length) {

            if (previous) {
                previous.state = 'complete'
            }

            this.clearTimer()
            setTimeout(() => this.$store.qv.thankYou.controller.start(), 1000)

            return

        }

        if (previous) {
            previous.state = 'complete'
        }

        if (current) {
            current.state = 'active'
        }

        this.index++

    },

    init() {

        this.progress()

        this.interval = setInterval(() => {
            this.progress()
        }, this.stepDelay)

        this.$store.qv.thankYou.controller.preload()

    },

    clearTimer() {

        if (this.interval) {
            clearInterval(this.interval)
        }

    },

    destroy() {
        this.clearTimer()
    },
})
