import Alpine from 'alpinejs'

export default (fieldName, required, prefillValue) => ({
    fieldName,
    required,
    hasSpouse: false,
    modelValue: prefillValue,
    person: {},
    children: [],
    personTemplate: {
        gender: '',
        birthday: '',
        /* eslint-disable camelcase */
        tobacco_use: '',
    },

    addSpouse() {

        this.hasSpouse = true
        this.person = { ...this.personTemplate }

    },

    addChild() {
        this.children.push({ ...this.personTemplate })
    },

    removePerson(type, index = 0) {

        if (type === 'spouse') {

            this.hasSpouse = false
            this.person = {}

        } else {
            this.children.splice(index, 1)
        }

    },

    setValue() {

        this.setFormData(this.fieldName, {
            spouse: this.person,
            children: this.children,
            children_count: this.children.length,
        })

    },

    async validateAndSetSpouse() {

        this.setValue()

        for (const key in this.personTemplate) {

            if (!this.person[ key ]) {
                return false
            }

        }

        if (this.person.birthday.length === 10) {
            Alpine.store('qv').webForm.state.canSubmit = true
        }

    },

    async validateAndSetChild() {

        this.setValue()

        const isValid = this.children.every(child => {

            for (const key in this.personTemplate) {

                if (!child[ key ]) {
                    return false
                }

            }

            if (child.birthday.length === 10) {
                return true
            }

            return false

        })

        if (isValid) {
            Alpine.store('qv').webForm.state.canSubmit = true
        }

    },
    init() {

        if (this.modelValue) {

            const spouse = this.modelValue.spouse
            const children = this.modelValue.children

            if (spouse) {

                this.hasSpouse = true
                this.person = { ...this.personTemplate }

            }

            if (children) {

                /* eslint-disable @typescript-eslint/no-unused-vars */
                for (const child in children) {
                    this.children.push({ ...this.personTemplate })
                }

            }

            this.$nextTick(() => {

                // Set the real values
                this.person = { ...spouse }
                this.children = children
                this.setValue()

            })

        }

        this.$watch('person', () => this.validateAndSetSpouse())
        this.$watch('children', () => this.validateAndSetChild())

    },
})
