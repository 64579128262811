export default ThankYou => (data, editing = false) => ({
    interval: null,
    editing,
    init() {

        if (this.editing === true) {
            return
        }

        this.$store.qv.audioManager.playCompletedWebFormAudio(data.completedAudio)
        this.$store.qv.runAfterEnterThankYouPageHook(data)
        ThankYou.controller.confirm(data.key)

        this.interval = setInterval(async() => {
            await ThankYou.controller.rotate()
        }, 5000)

    },
    destroy() {
        clearInterval(this.interval)
    },
})
