import Alpine from 'alpinejs'

const state = Alpine.reactive({
    canSubmit: true,
    loading: false,
    content: '',
    step: 0,
    formErrors: {},
})

const stateManager = new class {

    get canSubmit() {
        return state.canSubmit
    }

    set canSubmit(value) {
        state.canSubmit = value
    }

    get loading() {
        return state.loading
    }

    set loading(value) {
        state.loading = value
    }

    get webFormContent() {
        return state.content
    }

    set webFormContent(value) {
        state.content = value
    }

    set step(value) {
        state.step = value
    }

    get step() {
        return state.step
    }

    get formErrors() {
        return state.formErrors
    }

    set formErrors(value) {
        state.formErrors = value
    }

    incrementStep() {
        state.step++
    }

    hasErrors(fieldName) {
        return state.formErrors[ fieldName ]?.length
    }

    firstError(fieldName) {

        const errors = state.formErrors[ fieldName ] || []

        if (!errors.length) {
            return ''
        }

        return errors[ 0 ]

    }

}()

export default stateManager
